<template>
  <v-main>
    <v-container id="funda-main" class="font-nanumsquare" :fluid="fluid">
      <router-view :key="$route.fullPath + '&' + $store.state.time.current" />
    </v-container>
    <ChannelPluginStyle
      key="default-position"
      :stylePack="{ 'default-position': 'bottom: 30px; z-index: 199;' }"
    />
  </v-main>
</template>

<script>
export default {
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChannelPluginStyle: () =>
      import('@/components/channel/ChannelPluginStyle.vue'),
  },
}
</script>
