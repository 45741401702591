<template>
  <footer class="main-new-footer" :class="{ 'pb-150': isInvestPage }">
    <div class="container">
      <div class="mnf-row">
        <router-link to="/terms?mode=service" class="mnf-policy">
          서비스이용약관
        </router-link>
        <router-link to="/terms?mode=info" class="mnf-policy">
          개인정보취급방침
        </router-link>
        <router-link to="/terms?mode=online_investment" class="mnf-policy">
          온라인투자연계이용약관
        </router-link>
        <router-link to="/terms?mode=e_finance" class="mnf-policy">
          전자금융거래약관
        </router-link>
        <router-link to="/terms?mode=credit_info_util" class="mnf-policy">
          신용정보활용체제
        </router-link>
        <router-link
          :to="{ name: 'officialNotice' }"
          target="_blank"
          class="mnf-policy"
        >
          펀다 공시
        </router-link>
        <router-link to="/shareholders-notice" class="shareholders-notice">
          주주 공지사항
        </router-link>
      </div>
      <div class="mnf-row">
        <div class="mnf-col">
          <div class="mnf-col-sec">
            <p class="mnf-sec-title">(주)펀다 대표 박성준</p>
            <p>서울 서초구 사평대로53길 30 메이크잇 203호</p>
            <p class="mnf-sec-inline-pc">사업자등록번호 724-87-00107</p>
            <span class="mnf-sec-inline-pc mnf-text-separater">|</span>
            <p class="mnf-sec-inline-pc">통신판매업 2019-서울강남-05110</p>
            <p class="mnf-sec-inline-pc">
              온라인투자연계금융업 등록번호 : 2022-18
            </p>
          </div>
          <div class="mnf-col-sec"></div>
          <div class="mnf-col-sec"></div>
        </div>
        <div class="mnf-col">
          <div class="mnf-col-sec">
            <p>
              대출금리 연 최고 17.9% 이내, 연체이자율 : 약정금리 +3%
              (법정최고금리 20%이내),
            </p>
            <p>
              플랫폼 이용료 외 취급수수료 등 기타 부대비용은 없음, 조기상환조건
              없음
            </p>
          </div>
          <div class="mnf-col-sec">
            <p>
              중개수수료를 요구하거나 받는 행위는 불법입니다.<br
                class="mnf-break-m"
              />
              대출 시 귀하의 신용등급이 하락할 수 있습니다.<br
                class="mnf-break-m"
              />
              과도한 빚은 당신에게 큰 불행을 안겨줄 수 있습니다.
            </p>
          </div>
          <div class="mnf-col-sec mnf-contact">
            <p class="mnf-sec-title">
              펀다 <a href="tel:02-6925-1788">02-6925-1788</a
              >
            </p>
            <p class="mnf-sec-title">
              <a href="mailto:contact@funda.kr">contact@funda.kr</a>
            </p>
          </div>
          <div class="mnf-col-sec mnf-copyright">
            <span>Copyright © FUNDA 2020. All Right Reserved.</span>
            <div class="mnf-sns-icons">
              <a href="//www.facebook.com/fundap2p" target="_blank">
                <img src="@/assets/images/ic-ic-new-facebook.svg" />
              </a>
              <a href="//blog.naver.com/fundamaster" target="_blank">
                <img src="@/assets/images/ic-ic-new-blog.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isInvestPage: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        if (!!v && v.name === 'invest') {
          this.isInvestPage = true
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.pb-150 {
  padding-bottom: 150px !important;
}
footer {
  background: #454345;
  color: #fff;
  padding: 30px 0 10px 0 !important;
}
footer a {
  color: #fff !important;
}
/* start: main-new-footer */
footer.main-new-footer {
  background-color: #3e4650;

  @media (min-width: 1200px) {
    & > .container {
      width: 1170px;
    }
  }
}

.main-new-footer .mnf-row {
  display: flex;
  flex-wrap: wrap;
}

.main-new-footer .mnf-row:first-child {
  margin: 20px 0 40px;
  justify-content: flex-start;
  font-family: NanumSquare;
}

.main-new-footer .mnf-row:last-child {
  margin-bottom: 45px;
}

.main-new-footer .mnf-policy {
  font-size: 14px;
  font-weight: 900;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-right: 50px;
  margin-bottom: 8px;
}

.main-new-footer .shareholders-notice {
  font-size: 14px;
  font-weight: 900;
  line-height: 21px;
  color: rgba(255, 255, 255, 0.8) !important;
  margin-right: 50px;
  margin-bottom: 8px;
}

.main-new-footer .mnf-col {
  flex-basis: 460px;
}

.main-new-footer .mnf-col:first-child {
  margin-right: 226px;
}

.main-new-footer .mnf-col:first-child p,
span {
  font-family: AppleSDGothicNeo, NanumSquare;
}

.main-new-footer .mnf-col:last-child p,
span {
  font-family: NanumSquare;
}

.main-new-footer .mnf-col-sec {
  margin-bottom: 10px;
}

.main-new-footer .mnf-contact {
  margin: 16px 0 12px;
}

.main-new-footer .mnf-col p {
  margin: 0;
  text-align: left;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.main-new-footer .mnf-col-sec .mnf-underline-link {
  text-decoration: underline;
  cursor: pointer;
}

.main-new-footer .mnf-sec-title {
  font-weight: 700;
}

.main-new-footer .mnf-sec-inline-pc {
  display: inline-block;
}

.main-new-footer .mnf-text-separater {
  margin: 0px 10px;
}

.main-new-footer .mnf-break-m {
  display: none;
}

.main-new-footer .mnf-copyright {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main-new-footer .mnf-copyright span {
  font-family: AppleSDGothicNeo, NanumSquare;
  font-size: 13px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}

.main-new-footer .mnf-copyright .mnf-sns-icons a:first-child {
  margin-right: 14px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .main-new-footer .mnf-row .mnf-col:first-child {
    margin-right: 30px;
  }
}

@media screen and (max-width: 991px) {
  .main-new-footer .mnf-row:last-child {
    margin-bottom: 17px;
  }

  .main-new-footer .mnf-break-m {
    display: block;
  }

  .main-new-footer .container {
    padding: 0 25px;
  }

  .main-new-footer .mnf-row:first-child {
    margin: 15px 0 23px;
  }

  .main-new-footer .mnf-row .mnf-col:first-child {
    flex-basis: 100%;
    margin-right: 0;
  }

  .main-new-footer .mnf-row .mnf-policy {
    margin-right: 25px;
  }

  .main-new-footer .mnf-sec-inline-pc {
    display: block;
  }

  .main-new-footer .mnf-row .mnf-col .mnf-col-sec {
    margin-bottom: 17px;
  }

  .main-new-footer .mnf-row span.mnf-sec-inline-pc {
    display: none;
  }

  .main-new-footer .mnf-row .mnf-col .mnf-contact {
    margin: 24px 0 17px;
  }

  .main-new-footer .mnf-copyright span {
    flex-basis: 100%;
  }

  .main-new-footer .mnf-copyright .mnf-sns-icons {
    margin-top: 14px;
  }
}

@media screen and (max-width: 519px) {
  .main-new-footer .container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 360px) {
  .main-new-footer .mnf-row .mnf-policy {
    margin-right: 15px;
  }
}
/* end: main-new-footer */
</style>
